<script setup>
</script>

<!--
 template内にhtml,head,bodyは含まない
 -->
<template>
  <div>
    <div class="container my-container">
      <div class="d-flex justify-content-center mt-4">
        <!-- アプリから参照するのでリンクしない -->
        <img src="~/assets/images/stack3-icon.svg" class="stack3-icon" alt="Stack3">
      </div>
      <div class="mt-4 mb-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stack3-icon {
  width: 64px;
  height: 64px;
}
.header-logo {
  display: block;
  width: 200px;
}

.my-container {
  margin: 0 auto;
  width: 100%;
  max-width: 576px;
}
</style>